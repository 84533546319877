<template>
    <div class="profile__detail">
        <h2>{{ question.question }}</h2>
        <p v-html="question.answer"></p>
        <hr>
        <small class="text-center">Konnten wir deine Frage beantworten?</small>
        <div class="faq-vote">
            <div @click="upVoteAnswer" class="faq-vote__icon">
                <img ref="upVote" :src="upVoteImage()" alt="">
            </div>
            <div @click="downVoteAnswer" class="faq-vote__icon">
                <img ref="downVote" :src="downVoteImage()" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import firebase from 'firebase';
import axios from 'axios';

export default {
    name: 'SupportFAQDetail',
    data() {
        return {
            user: null,
            question: {},
            didDownVote: false,
            didUpVote: false,
        }
    },
    methods: {
        init(){
            let id = this.$route.params.id;
            this.user = firebase.auth().currentUser;
            let apiUrl = this.$store.getters.getApiUrl;
                axios
                .get(apiUrl + 'faq/' + id)
                .then(response => {
                    this.question = response.data;
                });
        },
        // TODO: send/save answer TBD
        downVoteAnswer() {
            this.didDownVote = !this.didDownVote;
            this.didUpVote = false; 
        },
        upVoteAnswer() {
            this.didUpVote = !this.didUpVote;
            this.didDownVote = false;
        },
        downVoteImage() {
            if (this.didDownVote) {
                return require("@/assets/ic_48_faq_down--selected.svg");
            }
            else {
                return require("@/assets/ic_48_faq_down.svg")
            }
        },
        upVoteImage() {
            if (this.didUpVote) {
                return require("@/assets/ic_48_faq_up--selected.svg");
            }
            else {
                return require("@/assets/ic_48_faq_up.svg")
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
    .profile__detail {
        h2 {
            font-weight: 700;
            margin: 38px 0 16px;
        }

        hr {
            margin: 40px 0 34px;
        }

        small {
            display: flex;
            justify-content: center;
        }
    }

    .faq-vote {
        display: flex;
        justify-content: center;
        margin: 20px 0 24px;

        &__icon {
            margin: 0 8px;
        }
    }
</style>